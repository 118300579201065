import React, { useState, useEffect } from 'react';
import HeaderBar from '../../../components/header-bar';
import MemberItem from '../../../components/member-item';
import { navigate } from 'gatsby';
import { useAPI } from '../../../utils/api';
import { showLoading, dismissLoading } from '../../../utils/loading';
import WebFn, { callAppFunc } from '../../../utils/jsbridge/index.js';
import { pageCheck } from '../../../utils/pageCheck';

const TaxList = () => {
  const api = useAPI();

  const [taxs, setTaxs] = useState([]);

  const deleteTax = (taxId) => {
    showLoading();
    api
      .deleteTax({ ID: taxId })
      .then((res) => {
        dismissLoading();
        // alert('刪除成功');
        window.location.reload();
      })
      .catch((error) => {
        dismissLoading();
        console.log(error);
      });
  };

  useEffect(() => {
    showLoading();
    api
      .getTax()
      .then((res) => {
        if (res.rows.length > 0) {
          setTaxs(res.rows);
        }
        dismissLoading();
      })
      .catch((error) => {
        dismissLoading();
      });
  }, []);

  return (
    <>
      <HeaderBar
        title="統一編號管理"
        withAddButton
        onAdd={() => {
          pageCheck(api, () => {
            navigate('/member/tax/add', {
              replace: false,
            });
          });
        }}
        goBack={() => {
          callAppFunc('backHome', {});
        }}
      />

      <div className="tax-list">
        {taxs &&
          taxs.map((data, index) => (
            <MemberItem
              data={data}
              isDefault={data.isDefault}
              tax
              key={index}
              onEdit={() => {
                pageCheck(api, () => {
                  navigate('/member/tax/edit', {
                    state: { data: { data } },
                    replace: false,
                  });
                });
              }}
              onDelete={() => {
                // if (confirm('是否確定刪除該筆資料？')) {
                //   deleteTax(data.ID);
                // }
                confirm('是否確定刪除該筆資料？').then(() => {
                  deleteTax(data.ID);
                });
              }}
            />
          ))}
      </div>

      <style jsx>
        {`
          .tax-list {
            background-color: #f2f2f2;
            padding: 18px;
          }
        `}
      </style>
    </>
  );
};

export default TaxList;
